import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';
import {
  UserCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Icon } from 'semantic-ui-react';
import ImageCard from './ImageCard';
import TimeAgo from './TimeAgo';
import FirebaseContext from '../store/firebase-context';
import { UserContext } from '../store/user-context';
import ExerciseMenu from './ExerciseMenu';
import LoadingBar from './LoadingBar';

export default function ExercsesCardSimple({ props, index }) {
  const [author, setAuthor] = useState({});
  const [isAuthor, setIsAuthor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollected, setIsCollected] = useState();
  const [isVotedUp, setIsVotedUp] = useState();
  const [isVotedDown, setIsVotedDown] = useState();
  const [voteCount, setVoteCount] = useState();
  const [copied, setCopied] = useState(false);
  const [commentCount, setCommentCount] = useState();
  const authorUid = props.author.uid;
  const { db } = useContext(FirebaseContext);
  const exerciseRef = doc(db, 'exercises', index);

  //const user = auth.currentUser;
  //Convert Timestamp
  const timeAgo = TimeAgo(props?.createdAt.seconds);

  const { user } = useContext(UserContext);
  const uid = user.uid;

  const copyText = (input) => {
    navigator.clipboard
      .writeText(input)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1300);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
        // You can show an error message or handle the error in an appropriate way here
      });
  };

  const shareLink = `${process.env.REACT_APP_EXPRESS_FRONTEND_PATH}exercises/${index}`;

  useEffect(() => {
    const postCollected = uid && props.collectedBy?.includes(uid);
    setIsCollected(postCollected ? postCollected : false);

    const postVotedUp = uid && props.votedUpBy?.includes(uid);
    setIsVotedUp(postVotedUp ? postVotedUp : false);
    const postVotedDown = uid && props.votedDownBy?.includes(uid);
    setIsVotedDown(postVotedDown ? postVotedDown : false);
    const postVoteCount =
      (props.votedUpBy?.length || 0) - (props.votedDownBy?.length || 0);
    setVoteCount(postVoteCount);
  }, []);

  async function toogleVoteUp() {
    if (isVotedUp) {
      await updateDoc(exerciseRef, { votedUpBy: arrayRemove(uid) });
      setIsVotedUp(false);
      setVoteCount(voteCount - 1);
    } else {
      await updateDoc(exerciseRef, { votedUpBy: arrayUnion(uid) });
      setIsVotedUp(true);
      setVoteCount(voteCount + 1);
      if (isVotedDown) {
        await updateDoc(exerciseRef, { votedDownBy: arrayRemove(uid) });
        setIsVotedDown(false);
        setVoteCount(voteCount + 2);
      }
    }
  }

  async function toogleVoteDown() {
    if (isVotedDown) {
      await updateDoc(exerciseRef, { votedDownBy: arrayRemove(uid) });
      setIsVotedDown(false);
      setVoteCount(voteCount + 1);
    } else {
      await updateDoc(exerciseRef, { votedDownBy: arrayUnion(uid) });
      setIsVotedDown(true);
      setVoteCount(voteCount - 1);
      if (isVotedUp) {
        await updateDoc(exerciseRef, { votedUpBy: arrayRemove(uid) });
        setIsVotedUp(false);
        setVoteCount(voteCount - 2);
      }
    }
  }

  async function toggleCollected() {
    if (isCollected) {
      await updateDoc(exerciseRef, { collectedBy: arrayRemove(uid) });
      setIsCollected(false);
    } else {
      await updateDoc(exerciseRef, { collectedBy: arrayUnion(uid) });
      setIsCollected(true);
    }
  }

  async function getAuthorProfile(authorUid) {
    try {
      const userRef = doc(db, 'users', authorUid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists) {
        const profile = userSnap.data();
        setAuthor({
          uid: authorUid,
          displayName: profile.displayName,
          username: profile.username,
          email: profile.email,
          avatar: profile.avatar,
        });
        setIsLoading(false);
      } else {
        setAuthor({
          uid: authorUid,
          displayName: 'User',
          username: 'user',
          email: 'noemail',
          avatar: null,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setAuthor({
        uid: authorUid,
        displayName: 'User',
        username: 'user',
        email: 'noemail',
        avatar: null,
      });
      setIsLoading(false);
    }
  }

  async function GetCommentNumber() {
    const commentsRef = collection(db, 'exercises', index, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);
    setCommentCount(commentsSnapshot.docs.length);
  }

  useEffect(() => {
    if (authorUid !== null) {
      //User logged in, fetch user profile from firebase
      getAuthorProfile(authorUid);
    } else {
      //User is not logged in, redirect user to login page
      setAuthor({
        uid: authorUid,
        displayName: 'Unknow User',
        username: 'unknowuser',
        email: 'unknowemail',
        avatar: null,
      });
      setIsLoading(false);
    }
    //get comment number
    GetCommentNumber();
    //Check user is equal to Author
    const userIsAuthor = uid === authorUid;
    setIsAuthor(userIsAuthor);
  }, []);

  const avatarUrl = author.avatar
    ? `https://cdn.discordapp.com/avatars/${author.uid}/${author.avatar}.png`
    : null;
  const avatar = avatarUrl ? (
    <img src={avatarUrl} alt={author.displayName} className='w-8 h-8' />
  ) : (
    <UserCircleIcon className='h-8 w-8 text-gray-500' />
  );

  return isLoading ? (
    <LoadingBar />
  ) : (
    <div
      id={`exercise-${index}`}
      className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-lg'
    >
      {!isAuthor && (
        <div className='px-4 py-5 sm:px-6'>
          <div className='flex justify-between group block flex-shrink-0'>
            <div className='flex items-center'>
              <a href='#'>{avatar}</a>
              <div className='ml-3'>
                <a
                  href='#'
                  className='text-sm font-medium text-gray-700 group-hover:text-gray-900'
                >
                  {author.displayName ? author.displayName : 'User'}
                </a>
                <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700'>
                  {timeAgo}
                </p>
              </div>
            </div>
            {isAuthor && (
              <div>
                <ExerciseMenu exerciseIndex={index} />
              </div>
            )}
          </div>

          {/* We use less vertical padding on card headers on desktop than on body sections */}
        </div>
      )}
      <div className='px-4 py-5 sm:p-6 flex flex-col space-y-4'>
        <div className='flex justify-between' to={`/exercises/${index}`}>
          <div className='flex space-x-4'>
            <span className='inline-flex items-center rounded-md bg-yellow-100 hover:bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-800 hover:text-yellow-900'>
              {props.symbol || props.instrument}
            </span>
            <span className='inline-flex items-center rounded-md bg-purple-100 hover:bg-purple-200 px-2 py-1 text-xs font-medium text-purple-700 hover:text-purple-800'>
              {props.timeFrame}
            </span>
            <span className='inline-flex items-center rounded-md bg-blue-100 hover:bg-blue-200 px-2 py-1 text-xs font-medium text-blue-700 hover:text-blue-800'>
              {`${props.startDate}${
                props.endDate === props.startDate ? '' : ` ~ ${props.endDate}`
              }`}
            </span>
            <span className='text-gray-500'>|</span>
            <span
              className={`inline-flex items-center rounded-md  px-2 py-1 font-medium ${
                voteCount > 0
                  ? 'bg-green-100 hover:bg-green-200 text-green-700 hover:text-green-800'
                  : voteCount < 0
                  ? 'bg-red-100 hover:bg-red-200 text-red-700 hover:text-red-800'
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700 hover:text-gray-800'
              }`}
            >
              Vote{` `}
              {voteCount}
            </span>
          </div>
          {isAuthor && (
            <div>
              <ExerciseMenu exerciseIndex={index} />
            </div>
          )}
        </div>
        <Link className='flex flex-col space-y-2' to={`/exercises/${index}`}>
          <h2 className='text-sm font-bold leading-6 text-gray-900'>
            {props.title}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
            className='mt-1 text-sm leading-6 text-gray-700 sm:mt-2'
          ></p>
        </Link>
        <div className='w-3/5 mx-auto'>
          {props.imageUrl ? (
            <ImageCard
              imageUrl={props.imageUrl}
              imageDescription={props.imageDescription}
              showThumbnails={false}
            />
          ) : (
            ''
          )}
        </div>
        <div className='relative flex flex-auto justify-evenly text-gray-600 py-2 border-t border-b border-gray-100'>
          <div className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'>
            <button
              className={`${isVotedDown ? 'text-red-500' : ''}
        hover:text-red-500 hover:scale-110 hover:translate-y-1 transition  ease-in-out duration-300
        `}
              onClick={toogleVoteDown}
            >
              <Icon name='caret down' />
            </button>
            <div>
              投票:{' '}
              <span
                className={`font-semibold ${
                  voteCount > 0
                    ? 'text-green-500'
                    : voteCount < 0
                    ? 'text-red-500'
                    : ''
                }`}
              >
                {voteCount}
              </span>
            </div>
            <button
              className={`${
                isVotedUp ? 'text-green-500' : ''
              } hover:text-green-500 hover:scale-110 hover:-translate-y-1 transition  ease-in-out duration-300`}
              onClick={toogleVoteUp}
            >
              <Icon name='caret up' />
            </button>
          </div>
          <Link
            className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'
            to={`/exercises/${index}`}
          >
            <div>留言 {commentCount}</div>
            <Icon name='comment outline' className='outline-none' />
          </Link>
          <button
            className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'
            onClick={toggleCollected}
          >
            <div>收藏</div>
            <Icon
              name={`bookmark${isCollected ? '' : ' outline'}`}
              className={`outline-none ${isCollected ? 'text-blue-500' : ''}`}
            />
          </button>
          <button
            onClick={() => copyText(shareLink)}
            className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'
          >
            <div>分享</div>
            <Icon name='share' className='outline-none' />
          </button>
          {copied && (
            <div>
              <div className='absolute  bottom-0 right-0 translate-y-6 rounded-md bg-green-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <CheckCircleIcon
                      className='h-5 w-5 text-green-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3'>
                    <p className='text-sm font-medium text-green-800'>
                      己複製連結！
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
