import { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  collection,
  where,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import { Waypoint } from "react-waypoint";
import { UserContext } from "../store/user-context";
import FirebaseContext from "../store/firebase-context";
import LoadingBar from "../components/LoadingBar";
import ExerciseCardSimple from "../components/ExerciseCardSimple";

export default function MyFavourites() {
  const { user } = useContext(UserContext);
  const [exercises, setExercises] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const lastPostSnapshotRef = useRef();
  const uid = user.uid;

  const { db } = useContext(FirebaseContext);
  console.log("my exercise", exercises);
  async function GetExercises() {
    setIsLoading(true);
    const fetchedData = [];
    const exercisesRef = collection(db, "exercises");

    try {
      const firstQ = await getDocs(
        query(
          exercisesRef,
          where("collectedBy", "array-contains", uid),
          orderBy("createdAt", "desc"),
          limit(2)
        )
      );

      const posts = firstQ.forEach((post) => {
        const dataObj = {
          id: post.id,
          data: post.data(),
        };

        fetchedData.push(dataObj);
        lastPostSnapshotRef.current = firstQ.docs[firstQ.docs.length - 1];
        setExercises(fetchedData);
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function GetFurtherExercises() {
    if (lastPostSnapshotRef.current) {
      const fetchedData = [];
      const exercisesRef = collection(db, "exercises");

      try {
        const furtherQ = await getDocs(
          query(
            exercisesRef,
            where("collectedBy", "array-contains", uid),
            orderBy("createdAt", "desc"),
            startAfter(lastPostSnapshotRef.current),
            limit(2)
          )
        );

        const posts = furtherQ.forEach((post) => {
          const dataObj = {
            id: post.id,
            data: post.data(),
          };

          fetchedData.push(dataObj);
          lastPostSnapshotRef.current = furtherQ.docs[furtherQ.docs.length - 1];
          setExercises([...exercises, ...fetchedData]);
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  useEffect(() => {
    GetExercises();
  }, []);

  return (
    <>
      {isLoading ? (
        <div>
          <Helmet>
            <title>Trader Piece - 我的收藏</title>
          </Helmet>
          <LoadingBar />
        </div>
      ) : (
        <div className="flex flex-col space-y-4 ">
          {exercises &&
            exercises?.map((singleExercise) => {
              return (
                <ExerciseCardSimple
                  key={singleExercise.id}
                  props={singleExercise.data}
                  index={singleExercise.id}
                />
              );
            })}
          <Waypoint onEnter={() => GetFurtherExercises()} />
          <div>我的收藏已見底了...</div>
        </div>
      )}
    </>
  );
}
