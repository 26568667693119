import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Helmet } from 'react-helmet';
import { isRouteErrorResponse, useParams } from 'react-router-dom';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  getDoc,
  collection,
  setDoc,
  Timestamp,
  getDocs,
  writeBatch,
} from 'firebase/firestore';
import {
  UserCircleIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
  TrashIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import { S3Client, S3 } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { Listbox, Transition } from '@headlessui/react';
import { Icon } from 'semantic-ui-react';
import TimeAgo from '../components/TimeAgo';
import ImageCard from '../components/ImageCard';
import ImageModal from '../components/ImageModal';
import { useImageModal } from '../components/useImageModal';
import ExerciseMenu from '../components/ExerciseMenu';
import FirebaseContext from '../store/firebase-context';
import { UserContext } from '../store/user-context';

function DisplayPortrait({ imageArray, contentArray, imageData, index }) {
  const { imageUrl, content } = imageData;
  const {
    fullScreenUrl,
    imageContent,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rotateImage,
  } = useImageModal(imageArray, contentArray);

  const imageLength = imageArray.length;
  const altText =
    imageUrl && typeof imageUrl === 'string'
      ? imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
      : '';

  function displayIndex(imageArray, currentImageUrl) {
    return imageArray.indexOf(currentImageUrl);
  }

  return (
    <div className='py-4 white' key={index}>
      <div className='mx-auto max-h-content lg:flex px-4 xl:justify-start border-l-4 border-indigo-400'>
        <div className='lg:flex lg:w-2/3 lg:shrink lg:grow-0'>
          <div className=' lg:h-auto lg:w-full lg:grow '>
            <img
              className='w-full border border-slate-400 rounded-md p-0.5 shadow-lg bg-gray-50 object-cover cursor-pointer'
              src={imageUrl}
              alt={altText}
              onClick={handleOpenModal}
            />
          </div>
        </div>
        <div className='mx-auto max-w-2xl pb-10 pt-10 lg:pt-0 lg:pb-2  lg:ml-8 lg:mr-2 lg:w-1/3 lg:max-w-lg lg:flex-none '>
          <p
            className='my-2 text-sm lg:text-base xl:text-lg leading-8 text-gray-700'
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
          <p className='text-sm lg:text-base xl:text-lg font-semibold leading-8 tracking-tight text-gray-900'>
            圖 {displayIndex(imageArray, imageUrl) + 1} / {imageArray?.length}
          </p>
        </div>
      </div>
      {isModalOpen && (
        <ImageModal
          imageLength={imageLength}
          imageUrl={fullScreenUrl}
          imageDescription={imageContent}
          onClose={handleCloseModal}
          onRotate={rotateImage}
          alt={fullScreenUrl.substring(fullScreenUrl.lastIndexOf('/') + 1)}
        />
      )}
    </div>
  );
}

function DisplayLandscape({ imageArray, contentArray, imageData, index }) {
  const { imageUrl, content } = imageData;
  const {
    fullScreenUrl,
    imageContent,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rotateImage,
    currentImageIndex,
  } = useImageModal(imageArray, contentArray);

  const imageLength = imageArray.length;
  const altText =
    imageUrl && typeof imageUrl === 'string'
      ? imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
      : '';
  function displayIndex(imageArray, currentImageUrl) {
    return imageArray.indexOf(currentImageUrl);
  }

  return (
    <div className='py-4 white' key={currentImageIndex}>
      <div className='mx-auto max-h-content px-4 border-l-4 border-indigo-400 '>
        <div className='lg:h-auto w-full'>
          <img
            className='w-full border border-slate-400 rounded-md p-0.5 shadow-lg bg-gray-50 object-cover cursor-pointer'
            src={imageUrl}
            alt={altText}
            onClick={handleOpenModal}
          />
        </div>
        <div className='w-full max-w-2xl pb-4 pt-4'>
          <p
            className='my-2 text-sm lg:text-base xl:text-lg  text-gray-700'
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
          <p className='text-sm lg:text-base xl:text-lg font-semibold leading-8 tracking-tight text-gray-900'>
            圖 {displayIndex(imageArray, imageUrl) + 1} / {imageArray?.length}
          </p>
        </div>
      </div>
      {isModalOpen && (
        <ImageModal
          imageLength={imageLength}
          imageUrl={fullScreenUrl}
          imageDescription={imageContent}
          onClose={handleCloseModal}
          onRotate={rotateImage}
          index={currentImageIndex}
          alt={fullScreenUrl.substring(fullScreenUrl.lastIndexOf('/') + 1)}
        />
      )}
    </div>
  );
}

export default function SingleExercise() {
  const [author, setAuthor] = useState({});
  const [isAuthor, setIsAuthor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({});
  const [imagesRatio, setImagesRatio] = useState([]);
  const [isCollected, setIsCollected] = useState();
  const [isVotedUp, setIsVotedUp] = useState();
  const [isVotedDown, setIsVotedDown] = useState();
  const [voteCount, setVoteCount] = useState();
  const [copied, setCopied] = useState(false);
  const [selected, setSelected] = useState(moods[5]);
  const [comment, setComment] = useState('');
  const [commentsId, setCommentsId] = useState([]);
  const [comments, setComments] = useState([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [emailLists, setEmailLists] = useState([]);

  const fileInputRef = useRef(null);

  const { exerciseId } = useParams();
  const { db } = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const uid = user.uid;

  const exerciseRef = doc(db, 'exercises', exerciseId);
  const batch = writeBatch(db);

  const copyText = (input) => {
    navigator.clipboard
      .writeText(input)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1300);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
        // You can show an error message or handle the error in an appropriate way here
      });
  };

  const shareLink = `${process.env.REACT_APP_EXPRESS_FRONTEND_PATH}exercises/${exerciseId}`;

  const userAvatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${uid}/${user.avatar}.png`
    : null;
  const userAvatar = userAvatarUrl ? (
    <img
      src={userAvatarUrl}
      alt={user.displayName}
      className='inline-block h-10 w-10 rounded-full'
    />
  ) : (
    <UserCircleIcon className='text-gray-500 inline-block h-10 w-10 rounded-full' />
  );

  const authorAvatarUrl = author.avatar
    ? `https://cdn.discordapp.com/avatars/${author.uid}/${author.avatar}.png`
    : null;
  const authorAvatar = authorAvatarUrl ? (
    <img src={authorAvatarUrl} alt={author.displayName} className='w-14 h-14' />
  ) : (
    <UserCircleIcon className='h-14 w-14 text-gray-500' />
  );

  //Uid of comment shoud be change!!!
  const commentAvatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${uid}/${user.avatar}.png`
    : null;
  const commentAvatar = commentAvatarUrl ? (
    <img
      src={commentAvatarUrl}
      alt={user.displayName}
      className='relative mt-3 h-10 w-10 flex-none rounded-full bg-gray-50'
    />
  ) : (
    <UserCircleIcon className='relative mt-3 text-gray-500 h-10 w-10 flex-none rounded-full' />
  );

  const timeAgo = TimeAgo(post.createdAt?.seconds);

  const getRatio = (imagesUrl, imagesDescription) => {
    const updatedImagesRatio = imagesUrl?.map((image, index) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const ratio = img.width / img.height;
        setImagesRatio((prevImagesRatio) => {
          const updatedImagesRatio = [...prevImagesRatio];
          updatedImagesRatio[index] = {
            imageUrl: image,
            imageRatio: ratio,
            content: imagesDescription[index],
          };
          return updatedImagesRatio;
        });
      };
      return null;
    });
  };

  function initialInteractiveButton(post) {
    const postCollected = uid && post.collectedBy?.includes(uid);
    setIsCollected(postCollected ? postCollected : false);

    const postVotedUp = uid && post.votedUpBy?.includes(uid);
    setIsVotedUp(postVotedUp ? postVotedUp : false);
    const postVotedDown = uid && post.votedDownBy?.includes(uid);
    setIsVotedDown(postVotedDown ? postVotedDown : false);
    const postVoteCount =
      (post.votedUpBy?.length || 0) - (post.votedDownBy?.length || 0);
    setVoteCount(postVoteCount);
  }

  async function toogleVoteUp() {
    if (isVotedUp) {
      await updateDoc(exerciseRef, { votedUpBy: arrayRemove(uid) });
      setIsVotedUp(false);
      setVoteCount(voteCount - 1);
    } else {
      await updateDoc(exerciseRef, { votedUpBy: arrayUnion(uid) });
      setIsVotedUp(true);
      setVoteCount(voteCount + 1);
      if (isVotedDown) {
        await updateDoc(exerciseRef, { votedDownBy: arrayRemove(uid) });
        setIsVotedDown(false);
        setVoteCount(voteCount + 2);
      }
    }
  }

  async function toogleVoteDown() {
    if (isVotedDown) {
      await updateDoc(exerciseRef, { votedDownBy: arrayRemove(uid) });
      setIsVotedDown(false);
      setVoteCount(voteCount + 1);
    } else {
      await updateDoc(exerciseRef, { votedDownBy: arrayUnion(uid) });
      setIsVotedDown(true);
      setVoteCount(voteCount - 1);
      if (isVotedUp) {
        await updateDoc(exerciseRef, { votedUpBy: arrayRemove(uid) });
        setIsVotedUp(false);
        setVoteCount(voteCount - 2);
      }
    }
  }

  async function toggleCollected() {
    if (isCollected) {
      await updateDoc(exerciseRef, { collectedBy: arrayRemove(uid) });
      setIsCollected(false);
    } else {
      await updateDoc(exerciseRef, { collectedBy: arrayUnion(uid) });
      setIsCollected(true);
    }
  }

  async function getSnapshotPostAndAuthorProfile() {
    const docRef = doc(db, '/exercises', exerciseId);

    try {
      const docSnap = await onSnapshot(docRef, (doc) => {
        const data = doc.data();
        setPost(data);
        getRatio(data.imageUrl, data.imageDescription);
        getAuthorProfile(data.author.uid);
        initialInteractiveButton(data);

        //Check user is author or not
        const checkAuthor = uid == data.author.uid;
        setIsAuthor(checkAuthor);
      });
    } catch (error) {
      console.log('getSnapshotPost Error', error);
    }
  }

  async function getAuthorProfile(uid) {
    setIsLoading(true);
    try {
      const userRef = doc(db, 'users', uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists) {
        const profile = userSnap.data();

        setAuthor({
          uid: uid,
          displayName: profile.displayName,
          username: profile.username,
          email: profile.email,
          avatar: profile.avatar,
        });
        setEmailLists((prevEmails) => [...prevEmails, profile.email]);
        setIsLoading(false);
      } else {
        setAuthor({
          uid: uid,
          displayName: 'User',
          username: 'user',
          email: 'noemail',
          avatar: null,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setAuthor({
        uid: uid,
        displayName: 'User',
        username: 'user',
        email: 'noemail',
        avatar: null,
      });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getSnapshotPostAndAuthorProfile();
  }, []);

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleFileDelete = (file) => {
    const updatedFiles = selectedFiles.filter(
      (selectedFile) => selectedFile !== file
    );
    setSelectedFiles(updatedFiles);
  };

  async function commentSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      //handle data upload

      const commentRef = await doc(
        collection(db, 'exercises', exerciseId, 'comments')
      );

      const imagePaths = selectedFiles.map(
        (selectedFile) =>
          `${process.env.REACT_APP_STORAGE_URL}/${uid}/exercises/${exerciseId}/comments/${commentRef.id}/${selectedFile.name}`
      );

      const docData = {
        commentUser: uid,
        commentUserDisplayname: user.displayName,
        commentUserUsername: user.username,
        commentContent: comment,
        imageUrl: imagePaths,
        commentUserEmail: user.email,
        createdAt: Timestamp.now(),
      };

      batch.set(commentRef, docData);
      if (Array.isArray(emailLists) && emailLists.length !== 0) {
        const uniqueEmailAddress = [...new Set(emailLists)];
        console.log('check this unique email lists', uniqueEmailAddress);
        const sendAllEmail = uniqueEmailAddress.map((recipient) => {
          if (user.email != recipient) {
            const mailRef = doc(collection(db, 'mail'));
            const emailDocData = {
              to: recipient,
              message: {
                subject: `${user.displayName}剛剛回應了${
                  author.displayName ? author.displayName : author.username
                }的交易練習`,
                html: `<div style="background-color: #f5f5f5; padding: 20px;">
                <p style="font-size: 18px; color: #333;">獵人朋友你好,</p>
                <p style="font-size: 16px; color: #333;">於練習${
                  post.title
                }中, ${
                  author.displayName ? author.displayName : author.username
                }回覆：</p>
                <p style="font-size: 14px; color: #777;">${
                  docData.commentContent
                }<p>
                ${
                  docData.imageUrl
                    ? `
                    <p style="font-size: 14px; color: #777;">
                      回覆包含圖片
                    </p>
                    `
                    : ''
                }
                </br>
                <a href="${
                  window.location.origin
                }/exercises/${exerciseId}" style="display: inline-block; padding: 10px 20px; background-color: #3490dc; color: #fff; text-decoration: none; border-radius: 4px; font-size: 14px;">按此前往查看</a>
                </br>
      
          <p style="font-size: 14px; color: #333;">交易員の大秘寶 - Trader Piece</p>
      <a href="https://traderpiece.com" style="font-size: 14px; color: blue; text-decoration: underline;">https://traderpiece.com</a>
          </div>`,
              },
            };
            batch.set(mailRef, emailDocData);
          }
        });

        await Promise.all(sendAllEmail);
      }

      //handle image upload
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_Access_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_Secret_KEY,
      };

      const s3Client =
        new S3({
          apiVersion: '2006-03-01',
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }) ||
        new S3Client({
          apiVersion: '2006-03-01',
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }); // Create an instance of S3 client

      const uploadPromises = selectedFiles.map((file) => {
        const upload = new Upload({
          client: s3Client,
          params: {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // Replace with your actual S3 bucket name
            Key: `${uid}/exercises/${exerciseId}/comments/${commentRef.id}/${file.name}`,
            Body: file,
          },
        });

        return upload.done();
      });
      await Promise.all(uploadPromises);
      console.log('All files uploaded successfully');
      await batch.commit();
      setComment('');
      setSelectedFiles([]);
      setIsLoading(false);
      //navigate("/");
    } catch (err) {
      console.log('comment submit err', err);
    }
  }

  useEffect(() => {
    //get all comments ID
    const commentsRef = onSnapshot(
      collection(db, 'exercises', exerciseId, 'comments'),
      (snapshot) => {
        const commentsData = snapshot.docs.map((doc) => doc.id);
        setCommentsId(commentsData);
      }
    );
  }, []);

  async function getAllComments() {
    try {
      setIsCommentsLoading(true);
      const commentsRef = collection(db, 'exercises', exerciseId, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      const commentsData = commentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setIsCommentsLoading(false);
      setComments(commentsData);
      const CommentUserEmails = commentsData.map(
        (comment) => comment.data.commentUserEmail
      );
      setEmailLists((prevEmails) => [...prevEmails, ...CommentUserEmails]);
    } catch (err) {
      console.log('get all comments err', err);
    }
  }

  useEffect(() => {
    //get all Comments by commentsID
    getAllComments();
    setComment('');
    setSelectedFiles([]);
  }, [commentsId]);

  return (
    <div>
      <Helmet>
        <title>{`Trder Piece - ${post?.title}`}</title>
      </Helmet>
      <div className='bg-white px-6 py-32 lg:px-8'>
        <div className='mx-auto w-full text-base leading-7 text-gray-700'>
          <div className='flex justify-between group block flex-shrink-0'>
            <div className='flex items-center'>
              <div>{authorAvatar}</div>
              <div className='ml-3'>
                <div className='text-sm lg:text-base xl:text-lg  font-medium text-gray-700 group-hover:text-gray-900'>
                  {author.displayName ? author.displayName : author.username}
                </div>
                <p className='text-xs lg:text-sm xl:text-base  font-medium text-gray-500 group-hover:text-gray-700'>
                  {timeAgo}
                </p>
              </div>
            </div>
            {isAuthor && (
              <div className=''>
                <ExerciseMenu exerciseIndex={exerciseId} />
              </div>
            )}
          </div>

          <div className='px-4 py-5 sm:p-6 flex flex-col space-y-4'>
            <div className='flex space-x-4 text-sm lg:text-base xl:text-lg '>
              <span className='inline-flex items-center rounded-md bg-yellow-100 hover:bg-yellow-200 px-2 py-1 font-medium text-yellow-800 hover:text-yellow-900'>
                {post?.symbol || post?.instrument}
              </span>
              <span className='inline-flex items-center rounded-md bg-purple-100 hover:bg-purple-200 px-2 py-1 font-medium text-purple-700 hover:text-purple-800'>
                {post?.timeFrame}
              </span>
              <span className='inline-flex items-center rounded-md bg-blue-100 hover:bg-blue-200 px-2 py-1 font-medium text-blue-700 hover:text-blue-800'>
                {post?.startDate} - {post?.endDate}
              </span>
              <span className='text-gray-500'>|</span>
              <span
                className={`inline-flex items-center rounded-md  px-2 py-1 font-medium ${
                  voteCount > 0
                    ? 'bg-green-100 hover:bg-green-200 text-green-700 hover:text-green-800'
                    : voteCount < 0
                    ? 'bg-red-100 hover:bg-red-200 text-red-700 hover:text-red-800'
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700 hover:text-gray-800'
                }`}
              >
                Vote{` `}
                {voteCount || 0}
              </span>
            </div>
            <div className='flex flex-col space-y-2'>
              <h1 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                {post?.title}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: post?.description,
                }}
                className='mt-1 text-sm lg:text-base xl:text-lg  leading-6 text-gray-700 sm:mt-2'
              ></p>
            </div>

            <div className='divide-y divide-solid divide-slate-400'>
              <h4 className='text-sm lg:text-base xl:text-lg font-bold'>
                分析及入市理據
              </h4>
              {imagesRatio?.map((image, index) => {
                if (image.imageRatio > 1.3) {
                  return (
                    <div>
                      <DisplayLandscape
                        imageArray={post.imageUrl}
                        contentArray={post.imageDescription}
                        imageData={image}
                        index={index}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <DisplayPortrait
                        imageArray={post.imageUrl}
                        contentArray={post.imageDescription}
                        imageData={image}
                        index={index}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className='relative flex flex-auto justify-evenly text-gray-600 py-2 border-t border-b border-gray-100'>
              <div className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'>
                <button
                  className={`${isVotedDown ? 'text-red-500' : ''}
        hover:text-red-500 hover:scale-110 hover:translate-y-1 transition  ease-in-out duration-300
        `}
                  onClick={toogleVoteDown}
                >
                  <Icon name='caret down' />
                </button>
                <div>
                  投票:{' '}
                  <span
                    className={`font-semibold ${
                      voteCount > 0
                        ? 'text-green-500'
                        : voteCount < 0
                        ? 'text-red-500'
                        : ''
                    }`}
                  >
                    {voteCount}
                  </span>
                </div>
                <button
                  className={`${
                    isVotedUp ? 'text-green-500' : ''
                  } hover:text-green-500 hover:scale-110 hover:-translate-y-1 transition  ease-in-out duration-300`}
                  onClick={toogleVoteUp}
                >
                  <Icon name='caret up' />
                </button>
              </div>
              <div className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'>
                <div>留言 {comments.length || 0}</div>
                <Icon name='comment outline' className='outline-none' />
              </div>
              <button
                className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'
                onClick={toggleCollected}
              >
                <div>收藏</div>
                <Icon
                  name={`bookmark ${isCollected ? '' : 'outline'}`}
                  className={`outline-none ${
                    isCollected ? 'text-blue-500' : ''
                  }`}
                />
              </button>
              <button
                onClick={() => copyText(shareLink)}
                className='flex flex-auto justify-center space-x-2 py-2 hover:bg-gray-50'
              >
                <div>分享</div>
                <Icon name='share' className='outline-none' />
              </button>
              {copied && (
                <div>
                  <div className='absolute  bottom-0 right-0 translate-y-6 rounded-md bg-green-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <CheckCircleIcon
                          className='h-5 w-5 text-green-400'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='ml-3'>
                        <p className='text-sm font-medium text-green-800'>
                          己複製連結！
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Below is Comment Text Area*/}
            <div className='flex items-start space-x-4'>
              <div className='flex-shrink-0'>{userAvatar}</div>
              <div className='min-w-0 flex-1'>
                <form action='#' className='relative'>
                  <div className='overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600'>
                    <label htmlFor='comment' className='sr-only'>
                      Add your comment
                    </label>
                    <textarea
                      rows={3}
                      name='comment'
                      id='comment'
                      className='block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                      placeholder='Add your comment...'
                      onChange={(e) => setComment(e.target.value)}
                    />

                    {/* Spacer element to match the height of the toolbar */}
                    <div className='py-2' aria-hidden='true'>
                      {/* Matches height of button in toolbar (1px border + 36px content height) */}
                      <div className='py-px'>
                        <div className='h-9' />
                      </div>
                    </div>
                  </div>

                  <div className='absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2'>
                    <div className='flex items-center space-x-5'>
                      <div className='flex items-center'>
                        <input
                          type='file'
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          multiple
                          accept='image/jpeg, image/jpg, image/png'
                        />

                        <button
                          type='button'
                          className='-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500'
                          onClick={handleAttachmentClick}
                        >
                          <PaperClipIcon
                            className='h-5 w-5'
                            aria-hidden='true'
                          />
                          <span className='sr-only'>Attach a file</span>
                        </button>
                      </div>
                      <div className='flex items-center'>
                        <Listbox value={selected} onChange={setSelected}>
                          {({ open }) => (
                            <>
                              <Listbox.Label className='sr-only'>
                                Your mood
                              </Listbox.Label>
                              <div className='relative'>
                                <Listbox.Button className='relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500'>
                                  <span className='flex items-center justify-center'>
                                    {selected.value === null ? (
                                      <span>
                                        <FaceSmileIcon
                                          className='h-5 w-5 flex-shrink-0'
                                          aria-hidden='true'
                                        />
                                        <span className='sr-only'>
                                          Add your mood
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        <span
                                          className={classNames(
                                            selected.bgColor,
                                            'flex h-8 w-8 items-center justify-center rounded-full'
                                          )}
                                        >
                                          <selected.icon
                                            className='h-5 w-5 flex-shrink-0 text-white'
                                            aria-hidden='true'
                                          />
                                        </span>
                                        <span className='sr-only'>
                                          {selected.name}
                                        </span>
                                      </span>
                                    )}
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave='transition ease-in duration-100'
                                  leaveFrom='opacity-100'
                                  leaveTo='opacity-0'
                                >
                                  <Listbox.Options className='absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm'>
                                    {moods.map((mood) => (
                                      <Listbox.Option
                                        key={mood.value}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : 'bg-white',
                                            'relative cursor-default select-none px-3 py-2'
                                          )
                                        }
                                        value={mood}
                                      >
                                        <div className='flex items-center'>
                                          <div
                                            className={classNames(
                                              mood.bgColor,
                                              'flex h-8 w-8 items-center justify-center rounded-full'
                                            )}
                                          >
                                            <mood.icon
                                              className={classNames(
                                                mood.iconColor,
                                                'h-5 w-5 flex-shrink-0'
                                              )}
                                              aria-hidden='true'
                                            />
                                          </div>
                                          <span className='ml-3 block truncate font-medium'>
                                            {mood.name}
                                          </span>
                                        </div>
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                    </div>
                    <div className='flex-shrink-0'>
                      <button
                        type='submit'
                        className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        onClick={(e) => commentSubmit(e)}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                </form>
                <div className='flex flex-wrap h-auto overflow-y-auto mt-2'>
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className='flex items-center space-x-2 mr-2 border border-gray-100 hover:shadow-md p-2 rounded-md'
                    >
                      <span>{file.name}</span>
                      <button
                        type='button'
                        onClick={() => handleFileDelete(file)}
                      >
                        <TrashIcon
                          className='h-4 w-4 text-red-500'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/*  Below is previous users comment Area*/}
            <div className='lg:col-start-3'>
              {/* Activity feed */}
              <h2 className='text-sm font-semibold leading-6 text-gray-900'>
                獵人回覆
              </h2>
              {isCommentsLoading ? (
                <div className='flex items-center justify-center h-screen'>
                  <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900'></div>
                  <div className='text-lg text-gray-500 ml-4'> Loading...</div>
                </div>
              ) : (
                <ul className='mt-6 space-y-6'>
                  {comments?.map((comment) => (
                    <li key={comment.id} className='relative flex gap-x-4'>
                      <>
                        {/* This is for demo, please dont user userAvator */}
                        {commentAvatar}

                        <div className='flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200'>
                          <div className='py-0.5 text-base leading-5 text-gray-500'>
                            <span className='font-bold text-gray-900'>
                              {comment.data.commentUserDisplayname
                                ? comment.data.commentUserDisplayname
                                : comment.data.commentUserUsername
                                ? comment.data.commentUserUsername
                                : 'Unknow User'}
                            </span>{' '}
                            回覆於 {TimeAgo(comment.data.createdAt.seconds)}
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: comment.data.commentContent,
                            }}
                            className='text-base leading-6 text-gray-700 mt-4'
                          ></p>

                          {/* Display attached images if available */}
                          {comment.data.imageUrl && (
                            <div className='mt-3 '>
                              <div className='border border-blue-200 rounded-md shadow'>
                                <ImageCard
                                  imageUrl={comment.data.imageUrl}
                                  imageDescription=''
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//Comment Text Area use

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const moods = [
  {
    name: 'Excited',
    value: 'excited',
    icon: FireIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Loved',
    value: 'loved',
    icon: HeartIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-400',
  },
  {
    name: 'Happy',
    value: 'happy',
    icon: FaceSmileIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-400',
  },
  {
    name: 'Sad',
    value: 'sad',
    icon: FaceFrownIcon,
    iconColor: 'text-white',
    bgColor: 'bg-yellow-400',
  },
  {
    name: 'Thumbsy',
    value: 'thumbsy',
    icon: HandThumbUpIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'I feel nothing',
    value: null,
    icon: XMarkIcon,
    iconColor: 'text-gray-400',
    bgColor: 'bg-transparent',
  },
];

const demoComments = [
  {
    id: 1,
    commentContent: 'ABC Comment1',
    imageUrl:
      'https://trader-piece-file-upload-storage.s3.ap-southeast-1.amazonaws.com/172124745343434752/exercises/TsBoM7Szfe2B7en9e9Id/comments/7hDD7Lk6wK3wb3OriXEm/HK33HKD_2024-01-11_17-02-35.png',
  },
  {
    id: 2,
    commentContent: 'CDE Comment2',
    imageUrl:
      'https://trader-piece-file-upload-storage.s3.ap-southeast-1.amazonaws.com/172124745343434752/exercises/TsBoM7Szfe2B7en9e9Id/comments/7hDD7Lk6wK3wb3OriXEm/HK33HKD_2024-01-11_17-02-29.png',
  },
];
