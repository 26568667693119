import React from 'react';
import ImageGallery from 'react-image-gallery';
import ImageModal from './ImageModal';
import { useImageModal } from './useImageModal';
import ResizeImage from './ResizeImage';

const ImageCard = ({ imageUrl, imageDescription, showThumbnails, showNav }) => {
  const {
    fullScreenUrl,
    imageContent,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rotateImage,
    currentImageIndex,
  } = useImageModal(imageUrl, imageDescription);

  const imageLength = imageUrl.length;
  const imageSets = imageUrl.map((url) => {
    const fileKey = url.replace(
      `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      ''
    );
    const alt = fileKey.substring(fileKey.lastIndexOf('/') + 1);
    const { resizedUrl } = ResizeImage(fileKey, 100);
    return {
      original: url,
      thumbnail: resizedUrl,
      originalAlt: alt,
      thumbnailAlt: alt,
    };
  });

  return (
    <div>
      <ImageGallery
        items={imageSets}
        showFullscreenButton={false}
        showPlayButton={false}
        onErrorImageURL={`${process.env.REACT_APP_STORAGE_URL}/utils/please-reload.png`}
        onClick={handleOpenModal}
        showThumbnails={showThumbnails}
        showNav={showNav}

        // onClick={(e) => handleImageClik(e)}
      />

      {isModalOpen && (
        <ImageModal
          imageLength={imageLength}
          imageUrl={fullScreenUrl}
          imageDescription={imageContent}
          onClose={handleCloseModal}
          onRotate={rotateImage}
          alt={fullScreenUrl.substring(fullScreenUrl.lastIndexOf('/') + 1)}
          index={currentImageIndex}
        />
      )}
    </div>
  );
};

export default ImageCard;
