import React, { useContext, useState, useRef } from 'react';
import {
  InstantSearch,
  SearchBox,
  useSearchBox,
  useInstantSearch,
  useHits,
} from 'react-instantsearch';
import aa from 'search-insights';
import { algoliaClient } from '../utilis/algolia';
import { UserContext } from '../store/user-context';

export default function MyTest() {
  const algoliaIndex = process.env.REACT_APP_ALGOLIA_INDEX;

  const appId = process.env.REACT_APP_ALGOLIA_APPID;
  const apiKey = process.env.REACT_APP_ALGOLIA_SEARCHONLY_API_KEY;

  const { user } = useContext(UserContext);

  //const { hits, results, sendEvent } = useHits();

  //console.log('this is test page hits', hits);

  console.log('user form test page', user);
  aa('init', {
    addId: appId,
    apiKey: apiKey,
  });

  aa('setUserToken', user.uid);

  return (
    <div>
      <h2>MyTest Page</h2>
      <InstantSearch
        searchClient={algoliaClient}
        indexName={algoliaIndex}
        insights={true}
      >
        <SearchBox placeholder='Try this box'></SearchBox>
        <div>
          New box
          <CustomSearchBox />
        </div>
      </InstantSearch>
    </div>
  );
}

export function CustomSearchBox(props) {
  const inputRef = useRef(null);
  const { indexUiState, status } = useInstantSearch();
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);

  const isSearchStalled = status === 'stalled';

  console.log('this is uistate', indexUiState);

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div>
      <input
        type='text'
        placeholder='搜尋人名/Symbol/日期/Time Frame。例如RK hk33 14/2/2024'
        className='w-full rounded-md placeholder:text-slate-400 placeholder:text-sm'
        onChange={(event) => {
          setQuery(event.currentTarget.value);
        }}
      />
      <span hidden={!isSearchStalled}>Searching…</span>
    </div>
  );
}
