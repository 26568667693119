export default function TimeAgo(timestamp) {
  const currentDate = new Date();
  const exerciseDate = new Date(timestamp * 1000);
  const timeDifference = currentDate.getTime() - exerciseDate.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  let timeAgo;

  if (minutesDifference < 60) {
    timeAgo = `${minutesDifference} minutes ago`;
  } else if (hoursDifference < 24) {
    timeAgo = `${hoursDifference} hours ago`;
  } else if (daysDifference < 7) {
    timeAgo = `${daysDifference} days ago`;
  } else {
    timeAgo = exerciseDate.toLocaleString();
  }

  return timeAgo;
}
