//Component for display full screen image
import React, { useEffect, useState } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ArrowUturnLeftIcon,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageModal = ({
  imageLength,
  imageUrl,
  imageDescription,
  onClose,
  onRotate,
  alt,
  index,
}) => {
  function calculateInitialScale() {
    const containerHeight = window.innerHeight; // Get the height of the container
    const imageHeight = 600; // Replace with the actual height of your image
    const scale = containerHeight / imageHeight;
    return scale;
  }

  const [isRotating, setIsRotating] = useState(false);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isRotating) return; // Ignore key presses if rotation is already in progress
      if (event.key === "ArrowLeft") {
        keyboardRotate("left");
      } else if (event.key === "ArrowRight") {
        keyboardRotate("right");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isRotating]);

  const keyboardRotate = (direction) => {
    setIsRotating(true);
    if (direction === "left") {
      onRotate("left");
    } else {
      onRotate();
    }
    setTimeout(() => {
      setIsRotating(false);
    }, 100);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-col space-y-2  bg-gray-800  z-50 ">
      {/* Content Part */}
      <div>
        <div className=" flex bg-white w-full text-xl">
          <div className="flex items-center space-x-4">
            <button
              className=" flex items-center justify-center shrink-0 bg-gray-50 w-12 h-full text-gray-800 hover:bg-gray-200  hover:text-gray-500 text-2xl focus:outline-none"
              onClick={onClose}
            >
              <ArrowUturnLeftIcon />
            </button>
            {imageDescription !== "<p>-</p>" && (
              <div className="max-h-36 py-2 pr-4 flex flex-1 flex-col justify-between ">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  分析或入市理據：
                </h3>
                <div className="mt-2 overflow-y-auto pr-4">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: imageDescription,
                    }}
                    className="text-base text-gray-700"
                  ></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Image part */}

      <div
        className="flex-grow relative flex items-center"
        onCLick={(e) => console.log("click div", e)}
      >
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <div className="relative h-full w-full flex-grow flex justify-center">
              <div className="tools absolute inset-x-0 top-0 z-20 flex justify-center">
                <button
                  onClick={() => zoomIn()}
                  className="w-10 h-10 text-blue-200 hover:text-blue-400 "
                >
                  <MagnifyingGlassPlusIcon />
                </button>
                <button
                  onClick={() => zoomOut()}
                  className="w-10 h-10 text-blue-200 hover:text-blue-400 "
                >
                  <MagnifyingGlassMinusIcon />
                </button>
                <button
                  onClick={() => resetTransform()}
                  className="w-10 h-10 text-blue-200 hover:text-blue-400 "
                >
                  <ArrowPathIcon />
                </button>
              </div>
              <div className="">
                <TransformComponent>
                  <img
                    src={imageUrl}
                    alt={alt}
                    style={{
                      width: "100%",
                      height: "100vh",
                      "object-fit": "contain",
                    }}
                    className="z-10"
                  />
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>

        {imageLength > 1 && (
          <button
            className="flex items-center justify-center absolute top-1/2 left-0 -translate-x-12 bg-blue-100 bg-opacity-50 grow-0 w-24 h-24 text-blue-300 shadow hover:text-blue-600 transition ease-in-out duration-100 hover:-translate-x-1 z-20"
            onClick={() => {
              onRotate("left");
            }}
          >
            <ChevronDoubleLeftIcon />
          </button>
        )}
        {imageLength > 1 && (
          <button
            className="flex items-center justify-center absolute top-1/2 right-0 translate-x-8 bg-blue-100 bg-opacity-50 grow-0 w-24 h-24 text-blue-300 shadow hover:text-blue-600 transition ease-in-out duration-100 hover:translate-x-1 z-20"
            onClick={onRotate}
          >
            <ChevronDoubleRightIcon />
          </button>
        )}
      </div>
      <div className="absolute inset-x-0 bottom-0 flex justify-center items-center w-full bg-gray-800 opacity-70 mx-auto z-20">
        <p className=" text-xl text-white font-bold z-20">
          圖 {index + 1}／{imageLength}
        </p>
      </div>
    </div>
  );
};

export default ImageModal;
