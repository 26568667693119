export const authErrorList = [
  {
    code: 'auth/invalid-email',
    mess: '電郵無效',
  },
  {
    code: 'auth/weak-password',
    mess: '密碼最少6個字元',
  },
  {
    code: 'auth/email-already-in-use',
    mess: '電郵已被使用',
  },
  {
    code: 'auth/user-not-found',
    mess: '沒有此用戶',
  },
  {
    code: 'auth/wrong-password',
    mess: '密碼錯誤',
  },
];
