import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  PhotoIcon,
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { collection, doc, Timestamp, setDoc } from 'firebase/firestore';
import Datepicker from 'react-tailwindcss-datepicker';
import { useNavigate } from 'react-router-dom';

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, S3 } from '@aws-sdk/client-s3';
import Dropzone from 'react-dropzone';

import FirebaseContext from '../store/firebase-context';
import { UserContext } from '../store/user-context';
import { GetAllCollections } from '../components/GetFirebase';

import { TextEditor } from '../components/TextEditor';
import ReactQuill, { displayName } from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function CreateExercise() {
  const { user } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [fileDescriptions, setFileDescriptions] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [timeframes, setTimeframes] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState('');
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(1),
  });
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(null);
  const [displayGroup, setDisplayGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { db } = useContext(FirebaseContext);
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event);
    setFiles([...files, ...selectedFiles]);
  };

  console.log('user', user);
  const handleRemove = (event, index) => {
    event.preventDefault();
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const updatedFileDescriptions = [...fileDescriptions];
    updatedFileDescriptions.splice(index, 1);
    setFileDescriptions(updatedFileDescriptions);
  };

  const handleFileDescriptionChange = (event, index) => {
    const updatedDescriptions = [...fileDescriptions];
    updatedDescriptions[index] = event;
    setFileDescriptions(updatedDescriptions);
  };

  const handleMoveUp = (event, index) => {
    event.preventDefault();
    if (index > 0) {
      const updatedFiles = [...files];
      const updatedDescriptions = [...fileDescriptions];
      [updatedFiles[index], updatedFiles[index - 1]] = [
        updatedFiles[index - 1],
        updatedFiles[index],
      ];
      [updatedDescriptions[index], updatedDescriptions[index - 1]] = [
        updatedDescriptions[index - 1],
        updatedDescriptions[index],
      ];
      setFiles(updatedFiles);
      setFileDescriptions(updatedDescriptions);
    }
  };

  const handleMoveDown = (event, index) => {
    event.preventDefault();
    if (index < files.length - 1) {
      const updatedFiles = [...files];
      const updatedDescriptions = [...fileDescriptions];
      [updatedFiles[index], updatedFiles[index + 1]] = [
        updatedFiles[index + 1],
        updatedFiles[index],
      ];
      [updatedDescriptions[index], updatedDescriptions[index + 1]] = [
        updatedDescriptions[index + 1],
        updatedDescriptions[index],
      ];
      setFiles(updatedFiles);
      setFileDescriptions(updatedDescriptions);
    }
  };

  const getSymbols = () => {
    GetAllCollections(db, 'symbols')
      .then((res) => {
        setSymbols(res.fetchedData);
      })
      .catch((error) => {
        console.log('get Symbols error', error);
      });
  };
  const getTimeFrame = () => {
    GetAllCollections(db, 'time-frames')
      .then((res) => {
        setTimeframes(res.fetchedData);
      })
      .catch((error) => {
        console.log('get time frame error', error);
      });
  };

  const handleReload = (e) => {
    e.preventDefault();
    getSymbols();
    getTimeFrame();
  };

  useEffect(() => {
    getSymbols();

    getTimeFrame();
  }, []);

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      //handle data upload
      const docRef = await doc(collection(db, 'exercises'));

      const imagePaths = files.map(
        (file) =>
          `${process.env.REACT_APP_STORAGE_URL}/${user.uid}/exercises/${docRef.id}/${file.name}`
      );

      const docData = {
        symbol: selectedSymbol,
        timeFrame: selectedTimeframe,
        startDate: date.startDate,
        endDate: date.endDate,
        title,
        description: description,
        createdAt: Timestamp.now(),
        author: {
          uid: user.uid,
          displayName: user.displayName,
          username: user.username,
        },
        imageUrl: imagePaths,
        imageDescription: fileDescriptions,
        displayGroup: ['933280539434188811'], //alvin discord group
      };
      await setDoc(docRef, docData);

      //handle image upload
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_Access_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_Secret_KEY,
      };

      const s3Client =
        new S3({
          apiVersion: '2006-03-01',
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }) ||
        new S3Client({
          apiVersion: '2006-03-01',
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }); // Create an instance of S3 client

      const uploadPromises = files.map((file) => {
        const upload = new Upload({
          client: s3Client,
          params: {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // Replace with your actual S3 bucket name
            Key: `${user.uid}/exercises/${docRef.id}/${file.name}`,
            Body: file,
          },
        });

        return upload.done();
      });

      await Promise.all(uploadPromises);
      console.log('All files uploaded successfully');

      setIsLoading(false);
      navigate('/');
    } catch (error) {
      console.log('Error occur during upload', error);
    }
  }

  return (
    <div>
      <Helmet>
        <title>Trader Piece - 上傳交易練習紀錄</title>
      </Helmet>
      <form onSubmit={(event) => onSubmit(event)}>
        <div className='space-y-12'>
          <div className='pb-12'>
            <h2 className='text-base font-semibold leading-7 text-gray-900'>
              發表交易練習記錄
            </h2>
            {/* <ReactQuill
            className='border rounded border-slate-100 shadow-sm hover:shadow hover:border-slate-200'
            theme='bubble'
            value={fileDescriptions}
            onChange={setFileDescriptions}
            placeholder='Texthere..'
            id='check-me'
          /> */}
            <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='col-span-full'>
                <label
                  htmlFor='images'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  交易練習圖上載
                </label>
                <div className='mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'>
                  <div className='text-center flex flex-col'>
                    {/* {files ? (
                    <img
                      src={preview}
                      alt='test'
                      className='mx-auto h-20'
                    ></img>
                  ) : (
                    <PhotoIcon
                      className='mx-auto h-12 w-12 text-gray-300'
                      aria-hidden='true'
                    />
                  )} */}
                    {files !== [] &&
                      files.map((file, index) => (
                        <div
                          key={index}
                          className='group ltr flex space-x-4 space-y-10  items-center'
                        >
                          <div className='mx-auto flex-none group-hover:scale-105  '>
                            交易練習圖{index + 1}：
                          </div>
                          <div className='flex flex-col flex-none text-gray-600  '>
                            <button
                              onClick={(event) => handleMoveUp(event, index)}
                              disabled={index === 0}
                              className='hover:text-gray-500 hover:scale-110 disabled:text-gray-200 '
                            >
                              <ChevronUpIcon className='w-12 h-12' />
                            </button>
                            <button
                              onClick={(event) => handleMoveDown(event, index)}
                              disabled={index === files.length - 1}
                              className='hover:text-gray-500 hover:scale-110 disabled:text-gray-200 '
                            >
                              <ChevronDownIcon className='w-12 h-12' />
                            </button>
                          </div>
                          <div className='w-auto flex space-x-4 border-s-4 border-green-500 group-hover:border-green-400 py-6 pl-6'>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              className='flex-auto w-1/3 group-hover:shadow-md group-hover:-translate-x-2 group-hover:-translate-y-2 transform-gpu'
                            />

                            <div className='flex-auto flex-col space-y-4'>
                              <div className='col-span-full'>
                                <label
                                  htmlFor={`image${index}-description`}
                                  className='hidden text-md font-medium leading-6 text-gray-900'
                                >
                                  分析或入市根據：
                                </label>
                                <div className='mt-2'>
                                  <ReactQuill
                                    className='border rounded border-slate-100 shadow-sm hover:shadow hover:border-slate-200'
                                    theme='bubble'
                                    id={`image${index}-description`}
                                    value={fileDescriptions[index] || '-'}
                                    onChange={(e) =>
                                      handleFileDescriptionChange(e, index)
                                    }
                                    placeholder='Texthere..'
                                  />
                                  {/* <textarea
                                  name={`image${index}-description`}
                                  id={`image${index}-description`}
                                  rows={8}
                                  value={fileDescriptions[index]}
                                  onChange={(e) =>
                                    handleFileDescriptionChange(e, index)
                                  }
                                /> */}
                                </div>
                              </div>
                              <div className='flex space-x-6'>
                                <button
                                  onClick={(event) =>
                                    handleRemove(event, index)
                                  }
                                  className='inline-flex items-center gap-x-1.5 rounded-md bg-red-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                                >
                                  <TrashIcon className='h-8 w-8'></TrashIcon>
                                  <span>Remov Image</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    <PhotoIcon
                      className='mx-auto h-12 w-12 text-gray-300'
                      aria-hidden='true'
                    />

                    <div className='mt-4 flex text-sm leading-6 text-gray-600'>
                      <label
                        htmlFor='files-upload'
                        className='relative cursor-pointer rounded-md bg-green-500 px-2 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:bg-green-400'
                      >
                        <span>Upload a file</span>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleFileChange(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>
                                Drag and drop a file here, or click to select a
                                file
                              </p>
                            </div>
                          )}
                        </Dropzone>
                        {/* <input
                        id='files-upload'
                        name='files-upload'
                        type='file'
                        multiple
                        className='sr-only'
                        onChange={handleFileChange}
                      />*/}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-span-full flex lg:space-x-8 md:space-x-6 space-x-4'>
                <div>
                  <label
                    htmlFor='symbol'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    資產
                  </label>
                  <div className='mt-2'>
                    <select
                      id='symbol'
                      name='symbol'
                      autoComplete='symbol-name'
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      value={selectedSymbol ? selectedSymbol : ' '}
                      onChange={(e) => setSelectedSymbol(e.target.value)}
                    >
                      <option></option>
                      {symbols &&
                        symbols.map((row, i) => {
                          return (
                            <option key={i} value={row}>
                              {row}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='timeFrame'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    切入Time Frame
                  </label>
                  <div className='mt-2'>
                    <select
                      id='timeFrame'
                      name='timeFrame'
                      autoComplete='timeFrame-name'
                      className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                      value={selectedTimeframe}
                      onChange={(e) => setSelectedTimeframe(e.target.value)}
                    >
                      <option></option>
                      {timeframes &&
                        timeframes.map((row, i) => {
                          return (
                            <option key={i} value={row}>
                              {row}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {/* <button
                className='bg-blue-200'
                onClick={(e) => {
                  handleReload(e);
                }}
              >
                Reload Asset
              </button> */}
              </div>
              <div className='col-span-full '>
                <div className='block text-sm font-medium leading-6 text-gray-900'>
                  練槍日期範圍：
                </div>
                <div className='col-span-full sm:col-span-12 flex lg:space-x-8 md:space-x-6 space-x-4'>
                  <div className='sm:col-span-2 sm:col-start-1'>
                    <Datepicker
                      value={date}
                      onChange={(chosenDate) => {
                        setDate(chosenDate);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='sm:col-span-4'>
                <label
                  htmlFor='title'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  標題
                </label>
                <div className='mt-2'>
                  <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                    <input
                      type='text'
                      name='title'
                      id='title'
                      autoComplete='title'
                      className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                      value={title}
                      onMouseDown={() => {
                        const titleCombine = `${
                          user.displayName
                        } - ${selectedSymbol} - ${selectedTimeframe} - ${
                          date.startDate
                        }${
                          date.endDate === date.startDate
                            ? ''
                            : ` ~ ${date.endDate}`
                        }`;

                        if (title === '') {
                          setTitle(titleCombine);
                        } else {
                        }
                      }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className='col-span-full'>
                <label
                  htmlFor='description'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  詳述：
                </label>
                <div className='mt-2'>
                  <TextEditor
                    setConvertedContent={setDescription}
                    convertedContent={description}
                    wrapperId='description'
                  />
                </div>
                <p className='mt-3 text-sm leading-6 text-gray-600'>
                  寫出你的分析及心得
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-6 flex items-center justify-end gap-x-6'>
          <button
            type='button'
            className=' text-sm font-semibold leading-6 text-gray-900'
          >
            捨棄
          </button>
          <button
            type='submit'
            className={`rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              isLoading ? 'disabled' : ''
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <svg
                className='animate-spin -inline-block w-5 h-5 rounded-full'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            ) : (
              '發表'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
