import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';
import {
  UserCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { Icon } from 'semantic-ui-react';
import ImageCard from './ImageCard';
import FirebaseContext from '../store/firebase-context';
import { UserContext } from '../store/user-context';
import { SearchContext } from '../store/search-context';
import ExerciseMenu from './ExerciseMenu';
import noimage from '../assets/no-image.jpg';

export default function SearchResultCard({ props }) {
  const {
    id,
    title,
    path,
    description,
    endDate,
    startDate,
    imageDescription,
    imageUrl,
    symbol,
    timeFrame,
  } = props;

  const index = id;

  const [author, setAuthor] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [voteCount, setVoteCount] = useState();
  const { db } = useContext(FirebaseContext);
  const exerciseRef = doc(db, 'exercises', index);

  const { user } = useContext(UserContext);
  const uid = user.uid;

  async function getAuthorProfile(authorUid) {
    try {
      const userRef = doc(db, 'users', authorUid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists) {
        console.log(
          'Step 2, getAuthorProfile',
          props.title,
          props.author.uid,
          authorUid
        );
        const profile = userSnap.data();
        console.log('i want to check thie profile uid', props.title, userSnap);
        setAuthor({
          uid: userSnap.id,
          displayName: profile.displayName,
          username: profile.username,
          email: profile.email,
          avatar: profile.avatar,
        });
        console.log(
          'Step 3, setAuthor',
          props.title,
          props.author.uid,
          authorUid,
          profile.displayName
        );
        setIsLoading(false);
      } else {
        setAuthor({
          uid: authorUid,
          displayName: 'User',
          username: 'user',
          email: 'noemail',
          avatar: null,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setAuthor({
        uid: authorUid,
        displayName: 'User',
        username: 'user',
        email: 'noemail',
        avatar: null,
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (props.author.uid !== null) {
      setAuthor({});
      console.log('Step 1, this is use Effect', props.title, props.author.uid);
      //User logged in, fetch user profile from firebase
      getAuthorProfile(props.author.uid);
    } else {
      //User is not logged in, redirect user to login page
      setAuthor({
        uid: props.author.uid,
        displayName: 'Unknow User',
        username: 'unknowuser',
        email: 'unknowemail',
        avatar: null,
      });
      setIsLoading(false);
    }
  }, [props]);

  const avatarUrl = author.avatar
    ? `https://cdn.discordapp.com/avatars/${author.uid}/${author.avatar}.png`
    : null;
  const avatar = avatarUrl ? (
    <img src={avatarUrl} alt={author.displayName} className='w-8 h-8' />
  ) : (
    <UserCircleIcon className='h-8 w-8 text-gray-500' />
  );

  console.log('check', props.title, author.uid, props.author.uid);

  return isLoading ? (
    <div className='flex items-center justify-center h-screen'>
      <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900'></div>
      <div className='text-lg text-gray-500 ml-4'> Loading...</div>
    </div>
  ) : (
    <div
      id={`exercise-${index}`}
      className='group border-solid border-2 my-6 border-indigo-200  overflow-hidden rounded-lg bg-white shadow-lg hover:bg-sky-50 hover:ring-sky-100'
    >
      <div className='px-4 py-5 sm:px-6'>
        <div className='flex justify-between group block flex-shrink-0'>
          <Link
            className='flex space-x-6 '
            to={`/exercises/${index}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='flex items-center'>
              {avatar}
              <div className='ml-3'>
                <div className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                  {author.displayName ? author.displayName : 'User'}
                </div>
              </div>
            </div>
            <div className='flex space-x-4'>
              <span className='inline-flex items-center rounded-md bg-yellow-100 group-hover:bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-800 hover:text-yellow-600'>
                {props.symbol || props.instrument}
              </span>
              <span className='inline-flex items-center rounded-md bg-purple-100 group-hover:bg-purple-200 px-2 py-1 text-xs font-medium text-purple-700 hover:text-purple-500'>
                {props.timeFrame}
              </span>
              <span className='inline-flex items-center rounded-md bg-blue-100 group-hover:bg-blue-200 px-2 py-1 text-xs font-medium text-blue-700 hover:text-blue-500'>
                {`${props.startDate}${
                  props.endDate === props.startDate ? '' : ` ~ ${props.endDate}`
                }`}
              </span>
              <span className='text-gray-500'>|</span>
              <span
                className={`inline-flex items-center rounded-md  px-2 py-1 font-medium ${
                  voteCount > 0
                    ? 'bg-green-100 group-hover:bg-green-200 text-green-700 hover:text-green-500'
                    : voteCount < 0
                    ? 'bg-red-100 group-hover:bg-red-200 text-red-700 hover:text-red-500'
                    : 'bg-gray-100 group-hover:bg-gray-200 text-gray-700 hover:text-gray-500'
                }`}
              >
                Vote{` `}
                {voteCount}
              </span>
            </div>
          </Link>
        </div>
        {/* We use less vertical padding on card headers on desktop than on body sections */}
      </div>

      <div className='px-4 py-5 sm:p-6 flex justify-start space-x-6'>
        <div className='w-60 h-auto shadow-md border-solid border-2 border-gray-200 transition duration-150 ease-out ease-in'>
          {props.imageUrl ? (
            <ImageCard
              imageUrl={props.imageUrl}
              imageDescription={props.imageDescription}
              showThumbnails={false}
              showNav={false}
            />
          ) : (
            <img
              className='h-20 w-auto'
              src={noimage}
              alt='No Image Avaliable'
            />
          )}
        </div>
        <Link
          className='mr-10 flex justify-between w-full'
          to={`/exercises/${index}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className=' flex flex-col space-y-2'>
            <h2 className='text-sm font-medium leading-6 text-gray-800 group-hover:text-gray-900'>
              {props.title}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: props.description,
              }}
              className='mt-1 text-sm leading-6 text-gray-700 group-hover:text-gray-800 sm:mt-2'
            ></p>
          </div>
          <div className='flex items-center invisible group-hover:visible group-hover:text-gray-700 hover:scale-110'>
            <ChevronRightIcon className='w-10 h-10' />
          </div>
        </Link>
      </div>
    </div>
  );
}
