import { useContext } from 'react';
import { UserContext } from './store/user-context';
import { SearchContext } from './store/search-context';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import LoadingBar from './components/LoadingBar';
import SearchResult from './pages/SearchResult';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import './App.css';

export default function App() {
  const { user, isLoading } = useContext(UserContext);
  const { searchText, searchResults } = useContext(SearchContext);
  let message = '';

  if (user.uid && user.access) {
    console.log('user logged in');
  }
  if (!user.access) {
    message = '你沒有權限觀看';
  }
  if (!user.uid) {
    message = '請先使用Discord登入';
  }

  function submitSignOut() {
    const removeCookie = (name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    removeCookie('uid');
  }

  return (
    <>
      <Header />
      {isLoading ? (
        <div>
          <Helmet>
            <title>Trader Piece</title>
          </Helmet>
          <LoadingBar />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Trader Piece</title>
          </Helmet>

          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            {message ? (
              <div className='rounded-md bg-yellow-50 p-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <ExclamationTriangleIcon
                      className='h-8 w-8 text-yellow-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-lg font-medium text-yellow-800'>
                      交易員の大秘寶 - 權限審核
                    </h3>
                    <div className='mt-2 text-lg text-yellow-700'>
                      <p>{message}</p>
                    </div>
                    {user.uid && (
                      <div className='mt-4 text-xl text-yellow-700'>
                        <a
                          href='/'
                          className='rounded-md bg-gray-500 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600'
                          onClick={() => submitSignOut()}
                        >
                          按此登出
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>{searchText ? <SearchResult /> : <Outlet />}</>
            )}
          </div>
        </div>
      )}
    </>
  );
}
