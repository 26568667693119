import React, { useEffect, useState } from 'react';
import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';

export default async function Objects() {
  const [objectList, setObjectList] = useState([]);

  const creds = {
    accessKeyId: process.env.REACT_APP_AWS_Access_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_Secret_KEY,
  };
  const client = new S3Client({
    apiVersion: '2006-03-01',
    region: process.env.REACT_APP_REGION,
    credentials: creds,
  });

  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // Replace with your actual S3 bucket name
    Prefix: 'user/alvin-group/',
  };

  try {
    const command = new ListObjectsV2Command(params);
    const response = await client.send(command);

    console.log('get data response', response.Contents);
    if (response.Contents) {
      setObjectList(response.Contents);
      console.log('this is the object list', objectList);
    }
  } catch (error) {
    console.log('get data error', error);
  }

  return <div>Objects</div>;
}
