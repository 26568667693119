import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function TextEditor({
  setConvertedContent,
  convertedContent,
  wrapperId,
  placeholder,
}) {
  const [editorState, setEditorState] = useState(() => {
    if (convertedContent) {
      const contentState = convertFromHTML(convertedContent);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });
  useEffect(() => {
    const plainText = editorState.getCurrentContent().getPlainText();
    if (plainText !== convertedContent) {
      const htmlText = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(htmlText);
    }
  }, [editorState, setConvertedContent]);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <>
      <div className="bg-gray-100 max-h-80 overflow-y-auto text-gray-700 text-center block w-full rounded-md border-0  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          //   wrapperClassName='border border-gray-300'
          editorClassName="bg-white px-2 border border-gray-200"
          toolbarClassName="border border-gray-200"
          wrapperId={wrapperId}
          placeholder={placeholder ? placeholder : ""}
          toolbar={{
            options: ["inline"],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },
          }}
          hashtag={{
            separator: " ",
            trigger: "#",
          }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: [
              { text: "JavaScript", value: "javascript", url: "js" },
              { text: "Golang", value: "golang", url: "go" },
            ],
          }}
        />
      </div>
    </>
  );
}

export function TextEditorWithIndex({
  setConvertedContent,
  convertedContent,
  wrapperId,
  placeholder,
}) {
  //   const [convertedContent, setConvertedContent] = useState(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  console.log("this is converted", convertedContent);

  return (
    <>
      <div className="bg-gray-100 max-h-80 overflow-y-auto text-gray-700 text-center block w-full rounded-md border-0  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <Editor
          editorState={convertedContent}
          onEditorStateChange={setEditorState}
          //   wrapperClassName='border border-gray-300'
          editorClassName="bg-white px-2 border border-gray-200"
          toolbarClassName="border border-gray-200"
          wrapperId={wrapperId}
          placeholder={placeholder ? placeholder : ""}
          toolbar={{
            options: ["inline"],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },
          }}
          hashtag={{
            separator: " ",
            trigger: "#",
          }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: [
              { text: "JavaScript", value: "javascript", url: "js" },
              { text: "Golang", value: "golang", url: "go" },
            ],
          }}
        />
      </div>
    </>
  );
}
