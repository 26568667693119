import algoliasearch from 'algoliasearch';

const appId = process.env.REACT_APP_ALGOLIA_APPID;
const apiKey = process.env.REACT_APP_ALGOLIA_SEARCHONLY_API_KEY;

const algoliaIndex = process.env.REACT_APP_ALGOLIA_INDEX;

export const algoliaClient = algoliasearch(appId, apiKey);

export const algolia = algoliaClient.initIndex(algoliaIndex); //To be inputed the index name
