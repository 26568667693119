import { NavLink } from "react-router-dom";

const tabs = [
  { name: "我的練習", href: "/my-treasury/exercises" },
  { name: "我的收藏", href: "/my-treasury/favourites" },
  { name: "我的筆記", href: "/my-treasury/notes" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MyTreasuryItems() {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex justify-center grow" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={({ isActive, isPending }) =>
                  classNames(
                    isActive
                      ? "border-green-500 text-gray-800"
                      : isPending
                      ? "border-green-500 text-gray-700"
                      : "border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-600",
                    "flex-auto border-b-4 py-4 px-1 text-center text-sm font-medium"
                  )
                }
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
        <p></p>
      </div>
    </div>
  );
}
