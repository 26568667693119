import {
  getDocs,
  collection,
  query,
  orderBy,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";

export async function GetAllCollections(db, collectionName) {
  const fetchedData = [];
  try {
    const res = await getDocs(collection(db, collectionName));
    res.forEach((row) => {
      fetchedData.push(row.id);
    });
  } catch (error) {
    console.log("error", error);
  }
  return { fetchedData };
}

//Get Sort Collection by accending Created Timestamp
export async function GetSortedLimitCollection(db, collectionName) {
  const fetchedData = [];

  const collectionRef = collection(db, collectionName);

  try {
    const q = await getDocs(query(collectionRef, orderBy("createdAt", "desc")));
    const posts = q.docs.forEach((post) => {
      const dataObj = {
        id: post.id,
        data: post.data(),
      };

      fetchedData.push(dataObj);
    });
  } catch (error) {
    console.log("error", error);
  }
  return { fetchedData };
}

//Get Single Post
export async function GetSinglePost(db, collectionName, postId) {
  const postData = {};
  const docRef = doc(db, collectionName, postId);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      Object.assign(postData, docSnap.data());
    }
  } catch (error) {
    // docSnap.data() will be undefined in this case
    console.log("No such document!", error);
  }
  return { postData };
}

export async function GetSinglePostSnapShot(db, collectionName, postId) {
  const postData = {};
  const docRef = doc(db, collectionName, postId);

  try {
    const docSnap = await onSnapshot(docRef, (doc) => {
      Object.assign(postData, doc.data());
    });
  } catch (error) {
    // docSnap.data() will be undefined in this case
    console.log("No such document!", error);
  }
  return { postData };
}
