import { useState } from 'react';

export const useImageModal = (imageUrl, imageDescription) => {
  const [fullScreenUrl, setFullScreenUrl] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [imageContent, setImageContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (e) => {
    const imageIndex = imageUrl?.findIndex(
      (url) =>
        decodeURIComponent(url) === decodeURIComponent(e.target.currentSrc)
    );

    setCurrentImageIndex(imageIndex);
    setImageContent(imageDescription[imageIndex]);
    setFullScreenUrl(e.target.currentSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setFullScreenUrl(null);
    setImageContent('');
    setIsModalOpen(false);
  };

  const rotateImage = (direction) => {
    if (fullScreenUrl) {
      const currentIndex = currentImageIndex;
      const newIndex =
        direction === 'left'
          ? (currentIndex - 1 + imageUrl.length) % imageUrl.length
          : (currentIndex + 1) % imageUrl.length;
      setFullScreenUrl(imageUrl[newIndex]);
      setImageContent(imageDescription[newIndex]);
      setCurrentImageIndex(newIndex);
    }
  };

  return {
    fullScreenUrl,
    imageContent,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    rotateImage,
    currentImageIndex,
  };
};
