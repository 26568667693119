import { useContext } from 'react';
import { SearchContext } from '../store/search-context';

export default function SearchBar() {
  const { handleSearch, searchText } = useContext(SearchContext);

  const handleChange = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <div>
      <input
        type='text'
        value={searchText}
        onChange={handleChange}
        placeholder='搜尋人名/Symbol/日期/Time Frame。例如RK hk33 14/2/2024'
        className='w-full rounded-md placeholder:text-slate-400 placeholder:text-sm'
      />
    </div>
  );
}
