import { getAuth, getRedirectResult, OAuthProvider } from 'firebase/auth';

export default function OAuth2LoggedIn() {
  const auth = getAuth();

  getRedirectResult(auth)
    .then((result) => {
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.

      // Get the OAuth access token and ID Token
      console.log('logged in');
      console.log('this is result', result);
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      console.log('access token', accessToken);
      console.log('id token', idToken);
    })
    .catch((error) => {
      console.log('error', error);
      // Handle error.
    });

  return <div>You are logged in</div>;
}
