import { OAuthProvider } from 'firebase/auth';
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
} from 'firebase/auth';

import { initializeApp } from 'firebase/app';

export default function discord() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECTID,
    storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_EB_MESSAGESENDERID,
    appId: process.env.REACT_APP_FB_APPID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENTID,
  };

  const firebaseInit = initializeApp(firebaseConfig);
  const auth = getAuth(firebaseInit);
  const provider = new OAuthProvider('oidc.discord-auth');

  console.log('This is provider', provider);
  const discordLogin = (e) => {
    e.preventDefault();

    signInWithPopup(auth, provider)
      .then((result) => {
        // User is signed in.
        // IdP data available using getAdditionalUserInfo(result)

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        console.log('credential', credential);
        console.log('access token', accessToken);
        console.log('id token', idToken);
      })
      .catch((error) => {
        // Handle error.
      });
    console.log('click');
  };

  console.log('running temp login');
  return (
    <div className=''>
      <div
        className='hidden lg:block lg:text-sm lg:font-semibold lg:leading-6
        lg:text-gray-900'
        onClick={(e) => discordLogin(e)}
      >
        Sign in with Redirect
      </div>
    </div>
  );
}
