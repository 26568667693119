import React, { createContext, useState } from 'react';
import { algolia } from '../utilis/algolia';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const handleSearch = (text) => {
    setIsSearchLoading(true);
    setSearchResults([]);
    setSearchText(text);
    algolia.search(text).then((result) => {
      console.log('result', result.hits);
      const searchResult = result.hits.map((hit) => {
        return {
          id: hit.objectID,
          title: hit.title,
          path: hit.path,
          author: hit.author,
          description: hit.description,
          endDate: hit.endDate,
          startDate: hit.startDate,
          imageDescription: hit.imageDescription,
          imageUrl: hit.imageUrl,
          symbol: hit.symbol,
          timeFrame: hit.timeFrame,
        };
      });
      setSearchResults(searchResult);
    });
    setIsSearchLoading(false);
  };

  console.log('Search Context Results', searchResults);
  return (
    <SearchContext.Provider
      value={{
        searchText,
        setSearchText,
        searchResults,
        handleSearch,
        isSearchLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
