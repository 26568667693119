import { useRouteError } from "react-router-dom";
export default function ErrorPage() {
  const error = useRouteError();
  console.log(error);

  return (
    <div
      id="error-page"
      className="py-4 flex flex-col items-center justify-center h-1/2 bg-gray-100"
    >
      <h1 className="text-4xl font-bold mb-4">行錯路！</h1>
      <p className="text-2xl text-gray-600 mb-2">呢度無你要搵既寶藏</p>
      <p className="text-xl font-bold text-gray-500">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
