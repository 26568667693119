import React, { useState, useEffect, useContext, useRef } from 'react';

import ExerciseCard from '../components/ExerciseCard';

import FirebaseContext from '../store/firebase-context';
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from 'firebase/firestore';
import { Waypoint } from 'react-waypoint';

export default function Exercises() {
  const [exercises, setExercises] = useState([]);
  const lastPostSnapshotRef = useRef();

  const { db } = useContext(FirebaseContext);

  async function GetExercises() {
    const fetchedData = [];
    const collectionRef = collection(db, 'exercises');

    try {
      const firstQ = await getDocs(
        query(collectionRef, orderBy('createdAt', 'desc'), limit(5))
      );

      const posts = firstQ.forEach((post) => {
        const dataObj = {
          id: post.id,
          data: post.data(),
        };

        fetchedData.push(dataObj);
        lastPostSnapshotRef.current = firstQ.docs[firstQ.docs.length - 1];
        setExercises(fetchedData);
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  async function GetFurtherExercises() {
    if (lastPostSnapshotRef.current) {
      const fetchedData = [];
      const collectionRef = collection(db, 'exercises');

      try {
        const furtherQ = await getDocs(
          query(
            collectionRef,
            orderBy('createdAt', 'desc'),
            startAfter(lastPostSnapshotRef.current),
            limit(5)
          )
        );

        const posts = furtherQ.forEach((post) => {
          const dataObj = {
            id: post.id,
            data: post.data(),
          };

          fetchedData.push(dataObj);
          lastPostSnapshotRef.current = furtherQ.docs[furtherQ.docs.length - 1];
          setExercises([...exercises, ...fetchedData]);
        });
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  useEffect(() => {
    GetExercises();
    //getSnapshotCollection();
  }, []);

  return (
    <div className='flex flex-col space-y-4 '>
      {/*
      <div className="flex space-x-4">
        
        <div>
          <label
            htmlFor="asset"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Asset
          </label>
          <select
            id="asset"
            name="asset"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue="AUDUSD"
          >
            <option>EURUSD</option>
            <option>USDJPY</option>
            <option>GBPUSD</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="date-range"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Date Range
          </label>
          <select
            id="date-range"
            name="date-range"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue=""
          >
            <option></option>
            <option></option>
            <option></option>
          </select>
        </div>
        <div>
          <label
            htmlFor="time-frame"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Time Frame
          </label>
          <select
            id="time-frame"
            name="time-frame"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue="M15"
          >
            <option>M5</option>
            <option>M15</option>
            <option>H1</option>
          </select>
        </div>
      </div>
  */}

      {exercises?.map((singleExercise) => {
        return (
          <ExerciseCard
            key={singleExercise.id}
            props={singleExercise.data}
            index={singleExercise.id}
          />
        );
      })}
      <Waypoint onEnter={() => GetFurtherExercises()} />
      <div>寶藏已見底了...</div>
    </div>
  );
}
