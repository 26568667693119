import { Outlet } from "react-router-dom";

import MyTreasuryItems from "../components/MyTreasuryItems";
import MyProfile from "./MyProfile";

export default function MyTreasury() {
  return (
    <>
      <div className="">
        <main className=" pb-8">
          <div className="flex flex-col space-y-4 mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Trader Piece</h1>
            <MyProfile />
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
              {/* Right column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-3">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    我的寶藏
                  </h2>
                  <div className="overflow-hidden shadow">
                    <MyTreasuryItems />
                    <div className="p-6">
                      <Outlet />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
              <span className="block sm:inline">&copy; 2023 Trader Piece</span>{" "}
              <span className="block sm:inline">All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
