import { createContext, useState, useEffect, useContext } from "react";
import FirebaseContext from "./firebase-context";
import { doc, getDoc } from "firebase/firestore";

export const UserContext = createContext({});

export default function UserContextProvider({ children }) {
  const { db } = useContext(FirebaseContext);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");

        if (cookie[0] === name) {
          return cookie[1];
        }
      }

      return null;
    };

    const uid = getCookieValue("uid");

    const fetchUserProfile = async (uid) => {
      try {
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists) {
          const profile = userSnap.data();
          setUser({
            uid: uid,
            displayName: profile.displayName,
            username: profile.username,
            email: profile.email,
            avatar: profile.avatar,
            access: profile.inAlvinGuild,
          });
          setIsLoading(false);
        } else {
          //Redirect user to login page
          console.log(
            "User profile does not exist, redirect user to login process"
          );
          setUser({});
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Error fetching user profile:", err);
        setUser({});
        setIsLoading(false);
      }
    };
    if (uid !== null) {
      //User logged in, fetch user profile from firebase

      fetchUserProfile(uid);
    } else {
      //User is not logged in, redirect user to login page
      console.log("uid not exist, futher to login process");
      setUser({});
      setIsLoading(false);
    }
  }, []);

  const ctxValue = { user, isLoading };
  return (
    <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>
  );
}
