import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import UserContextProvider from './store/user-context';
import { SearchProvider } from './store/search-context.js';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import SignIn from './pages/Signin';
import SignUp from './pages/Signup';
import CreateExercise from './pages/CreateExercise';
import EditExercise from './pages/EditExercise.js';
import Exercises from './pages/Exercises';
import MyTreasury from './pages/MyTreasury';
import Notes from './pages/Notes';
import SingleExercise from './pages/SingleExercise';
import Objects from './pages/ShowObjects';
import MyFavourites from './pages/MyFavourites.js';
import MyExercises from './pages/MyExercises.js';
import MyNotes from './pages/MyNotes.js';
import Discord from './pages/Discord';
import OAuth2LoggedIn from './pages/OAuthLoggedIn';
import MyTest from './pages/MyTest';
import Home from './pages/Home.js';
import ErrorPage from './pages/ErrorPage.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/create-exercise',
        element: <CreateExercise />,
      },
      {
        path: '/exercises',
        element: <Exercises />,
      },
      {
        path: '/exercises/:exerciseId',
        element: <SingleExercise />,
      },
      { path: '/exercises/:exerciseId/edit', element: <EditExercise /> },
      {
        path: '/my-treasury',
        element: <MyTreasury />,
        children: [
          { path: '/my-treasury/favourites', element: <MyFavourites /> },
          { path: '/my-treasury/exercises', element: <MyExercises /> },
          { path: '/my-treasury/notes', element: <MyNotes /> },
        ],
      },

      { path: '/notes', element: <Notes /> },
      {
        path: '/getdata',
        element: <Objects />,
      },
      {
        path: '/discord',
        element: <Discord />,
      },
      {
        path: '/oauth2/callback',
        element: <OAuth2LoggedIn />,
      },
      {
        path: '/mytest',
        element: <MyTest />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <UserContextProvider>
      <SearchProvider>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <RouterProvider router={router} />
        </div>
      </SearchProvider>
    </UserContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
