import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { collection, query, where, getDocs } from "firebase/firestore";
import { UserContext } from "../store/user-context";
import FirebaseContext from "../store/firebase-context";
import LoadingBar from "../components/LoadingBar";
import { UserCircleIcon } from "@heroicons/react/24/solid";

export default function MyProfile() {
  const { user, isLoading } = useContext(UserContext);
  const [postsCount, setPostsCount] = useState("");
  const [collectedCount, setCollectedCount] = useState("");

  const { db } = useContext(FirebaseContext);
  const exercisesRef = collection(db, "exercises");

  async function getProfileCount() {
    const postCount = query(exercisesRef, where("author.uid", "==", user.uid));
    const postCountSnap = await getDocs(postCount);
    setPostsCount(postCountSnap.size);
    const collectedCount = query(
      exercisesRef,
      where("collectedBy", "array-contains", user.uid)
    );
    const collectedCountSnap = await getDocs(collectedCount);
    setCollectedCount(collectedCountSnap.size);
  }
  useEffect(() => {
    getProfileCount();
  }, []);

  const avatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${user.uid}/${user.avatar}.png`
    : null;
  const avatar = avatarUrl ? (
    <img
      src={avatarUrl}
      alt={user.displayName}
      className="h-20 w-20 flex-shrink-0 rounded-full"
    />
  ) : (
    <UserCircleIcon className="h-20 w-20 flex-shrink-0 rounded-full text-gray-500" />
  );

  return (
    <>
      {isLoading ? (
        <div>
          <Helmet>
            <title>Trader Piece - 我的寶庫</title>
          </Helmet>
          <LoadingBar />
        </div>
      ) : (
        <div className="flex flex-1 space-x-10 py-4">
          {avatar}
          <div className="">
            <h3 className="text-lg font-medium text-gray-900">
              {user.displayName}
            </h3>
            <p className=" text-sm font-medium text-gray-600">
              @{user.username}
            </p>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Guilds</dt>
              <dt className="sr-only">Role</dt>
            </dl>
            <div className="flex space-x-6">
              <div>{postsCount + " 篇練習"}</div>
              <div>{collectedCount + " 篇收藏"}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
