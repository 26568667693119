export default function ResizeImage(fileKey, widthSize) {
  const imageRequest = JSON.stringify({
    bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    key: fileKey,
    edits: {
      resize: {
        width: widthSize,
      },
    },
  });

  const resizedUrl = `${process.env.REACT_APP_CLOUDFORMATION_API}/${btoa(
    imageRequest
  )}`;

  return { resizedUrl };
}
