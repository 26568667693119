import React, { createContext } from 'react';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_EB_MESSAGESENDERID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENTID,
};

const firebaseInit = initializeApp(firebaseConfig);

const FirebaseContext = createContext({
  db: getFirestore(firebaseInit),
  auth: getAuth(firebaseInit),
});

export default FirebaseContext;
