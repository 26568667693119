import React, { useContext } from 'react';
import { SearchContext } from '../store/search-context';
import LoadingBar from '../components/LoadingBar';
import SearchResultCard from '../components/SearchResultCard';

export default function SearchResult() {
  const { searchResults, isSearchLoading } = useContext(SearchContext);

  console.log('search result in result page', searchResults);
  console.log('search is loading?', isSearchLoading);
  return (
    <>
      {isSearchLoading ? (
        <div>
          <LoadingBar />
        </div>
      ) : (
        <div className='pb-6'>
          <h2 className='font-bold text-xl text-gray-600'>搜尋結果</h2>
          <ul>
            {searchResults?.map((result) => {
              return <SearchResultCard props={result} />;
            })}
          </ul>
        </div>
      )}
    </>
  );
}
